import React, { Component } from "react";
import { Modal, Button, Header, Divider } from "semantic-ui-react";
import { OptionsContext } from "../OptionsContext";
import { toast } from "react-toastify";
import { getSMSInfoByUser } from "../lib/apiCalls";


class SendSMSModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      SMSInfo: {}
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  onChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  openModal = () => {
    if (this.props.userId && this.props.phoneNumber) {
      // We have nowhere to indicate a background XHR request is running at the moment.
      // In the future we could add a spinner animation on the SMS button if visual feedback was needed.
      //this.setState({ loading: true });
      getSMSInfoByUser({
          userId: this.props.userId,
          phoneNumber: this.props.phoneNumber 
        },
        (error, response) => {
          if (error) {
            if ( error.response && error.response.data && error.response.data.message ) {
              toast.error(error.response.data.message);
            }
            else {
              toast.error("Error while loading SMS information.");
            }
            return;
          }
          this.setState({
            open: true,
            loading: false,
            SMSInfo: response
          });
       }
      );
    }
  };
  closeModal = () => {
    this.setState({ open: false });
  };
  render() {
    let open = this.state.open;
    return (
      <Modal
        open={open}
        onClose={this.closeModal}
        closeIcon
        trigger={
          <Button
            size="small"
            icon="comment"
            color={this.props.buttonColor}
            content="SMS"
            onClick={this.openModal}
          />
        }
      >
        <Modal.Header>SMS Settings for {this.formatPhoneNumber(this.state.SMSInfo.phoneNumber)}</Modal.Header>
        <Modal.Content>
          {/* <p>PVConversationID {this.state.SMSInfo.PVConversationID}</p>
          <p>PVConversationURL {this.state.SMSInfo.PVConversationURL}</p>
          <p>extension {this.state.SMSInfo.extension}</p> */}
          <Header as="h3" floated="left" >
            SMS Console
            <Header.Subheader>To view SMS conversation history with this number or send a message click <strong>Open In PrimeVox</strong>.</Header.Subheader>
          </Header>
          <Divider clearing hidden />
          <Button content="Open In PrimeVox" onClick={() => this.handleOpenInPrimeVox(this)} color="green" />
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" onClick={this.closeModal} color="green" />
        </Modal.Actions>
      </Modal>
    );
  };
  openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  handleOpenInPrimeVox(modal) {
    modal.openInNewTab(modal.state.SMSInfo.PVConversationURL);
    modal.closeModal();
  };
  // https://learnersbucket.com/examples/javascript/how-to-format-phone-number-in-javascript/#google_vignette
  formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };
  
    return null
  };
}
SendSMSModal.defaultProps = {
  userId: null,
  buttonColor: "green"
};

export default props => (
  <OptionsContext.Consumer>
    {() => {
      return <SendSMSModal {...props} />;
    }}
  </OptionsContext.Consumer>
);
